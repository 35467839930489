import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { css } from '@emotion/react';
import { t } from '../../domain/services/configService';
import { TextInput } from '../responsive/atoms/TextInput';
import { IconWrapper } from '../responsive/molecules/IconWrapper';

const styles = {
  form: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 85%;
    max-width: 1600px;
  `
};

export const ExpandedSearch = ({ closeCb }) => {
  const handleKeyUp = event => {
    if (
      (event.key === 'Enter' || event.which === 13 || event.keyCode === 13) &&
      event.target.value &&
      event.target.value.length > 1
    ) {
      navigate(`/search?q=${event.target.value.toLowerCase()}`);
      closeCb();
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
  };

  return (
    <form
      action="/search"
      method="GET"
      css={styles.form}
      onSubmit={handleSubmit}
    >
      <IconWrapper
        icon="search"
        size="large"
        underlined
        color="white"
        backgroundColor="transparent"
        borderColor="white"
      >
        <TextInput
          name="q"
          placeholder={t('Search')}
          onKeyUp={handleKeyUp}
          autoFocus
          size="large"
          icon="search"
          uppercase
          inverted
        />
      </IconWrapper>
    </form>
  );
};

ExpandedSearch.propTypes = {
  closeCb: PropTypes.func.isRequired
};
